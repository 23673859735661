import gql from 'graphql-tag'

export const GET_PROJECT_QUERY = gql`
    query getProjectById($path: ID!, $startDate: Time, $endDate: Time) {
        project(fullPath: $path) {
            id
            issues(createdAfter: $startDate, createdBefore: $endDate) {
                nodes {
                    iid
                    state
                    title
                    description
                    totalTimeSpent
                    timeEstimate
                    createdAt
                    updatedAt
                    dueDate
                    webUrl
                    labels {
                        nodes {
                            color
                            title
                        }
                    }
                    milestone {
                        id
                        title
                        description
                        dueDate
                        startDate
                    }
                    assignees {
                        nodes {
                            name
                        }
                    }
                }
            }
        }
    }
`

export const GET_ALL_PROJECTS = gql`
query {
    projects {
        name
        slug
        path
        project_id
    }
}
`

export const GET_PROJECT_INFO = gql`
     query getProjectById($path: ID!) {
        project(fullPath: $path) {
            id
            name
            description
            createdAt
            avatarUrl
        }
    }
`

export const GET_PROJECTS_QUERY = gql`
    query getProjectById($ids: [ID!]) {
        project(fullPath: $ids) {
            id
            name
            description
            lastActivityAt
            createdAt
        }
    }
`