// import {computed, reactive, ref} from "vue";
import {useQuery, useResult} from "@vue/apollo-composable";
import {GET_ALL_PROJECTS} from "@/graphql/queries/projectQuery";

export const getAllProjectsCMS = () => {
  const { result, loading, error} = useQuery(
      GET_ALL_PROJECTS,
      null,
      {
        clientId: "cmsClient"
      }
  )

  const projects = useResult(result, null, data => data.projects )

  return {
    projects,
    loading,
    error
  }
}

