<template>
  <Page page-title="Reports" customClass="is-tasks mt-6" :hasRightBar="true">
    <Columns>
      <Column customClass="is-half">
           <h4 class="title is-5 mb-3">Choose Project</h4>
          <div class="field has-addons">
            <div class="control is-expanded">
              <div class="select is-fullwidth is-primary" :class="loading ? 'is-loading' : ''">
                <select v-model="pathID">
                  <option>Select project</option>
                  <option v-for="opt in projects" :key="opt.project_id" :value="opt.path">{{ opt.name }}</option>
                </select>
              </div>
            </div>

            <div class="control">
              <button class="button is-primary" @click.prevent="selectHandle">Submit</button>
            </div>
          </div>

          {{ pathID }}

      </Column>

      <Column>

      </Column>

    </Columns>


    <Columns v-if="loading">
      <Column>
        <Loading/>
      </Column>
    </Columns>

    <!-- content-->

    <Columns>
      <Column>
        <!-- data -->
        <Columns>
            <Column>
                <h4 class="title is-5 mb-3">Data</h4>
            </Column>
        </Columns>

      </Column>
    </Columns>

    <!-- content-->


    <template #rightbar>
      <Columns>
        <Column customClass="mt-6 pt-6">
            <div class="box">
              <h4 class="title is-5 mb-3">Filter</h4>
                <div class="field">
                  <div class="control">
                    <datepicker v-model="sdate" format="YYYY-MM-DD" class="input" />
                  </div>
                </div>

                <div class="field">

                  <div class="control ">
                    <datepicker v-model="edate" format="YYYY-MM-DD" class="input" :upperLimit="new Date()"  />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button class="button is-primary" @click.prevent="filterByDateHandle">Submit</button>
                  </div>
                </div>
              </div>

        </Column>
      </Columns>
    </template>
  </Page>
</template>

<script>
import { computed, defineAsyncComponent, reactive, ref, toRefs } from 'vue'
import { getAllProjectsCMS } from '@/use/useProjects'
import Datepicker from 'vue3-datepicker'

export default {
  components: {
    Page: defineAsyncComponent(() => import('@/components/layout/Page.vue')),
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    Loading: defineAsyncComponent(() => import('@/components/general/Loading')),
    Datepicker
  },

  setup () {

    const state = reactive({
      pathID: '',
      startDate: "1900-01-01",
      endDate: "2999-01-01",
    })

    let sdate = ref(null)
    let edate = ref(null)

    const { projects, loading } = getAllProjectsCMS()

    const selectHandle = () => {
      console.log('ad')
    }

    const filterByDateHandle = () => {
      console.log(sdate.value)
    }


    

    return {
      ...toRefs(state),
      selectHandle,
      filterByDateHandle,
      projects,
      loading,
      sdate,
      edate
    }
  }
}
</script>

<style lang="scss" scoped>

</style>